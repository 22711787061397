(function ($) {
  Drupal.behaviors.special_offer_row = {
    attach: function (context) {
      $('.js-special-offer-row', context).each(function () {
        var $aTag = $(this).find('a');

        $(this)
          .find('.js-special-offer-image')
          .click(function (e) {
            var $cta = $('#' + $(this).data('ctaId'));

            if ($cta.length) {
              $cta[0].click();
            } else if ($aTag.length) {
              $aTag.first()[0].click();
            }
          });
      });
    }
  };
})(jQuery);
